import Header from "./Components/Header";
import Container from "./Components/Container";
import AddForm from "./Components/AddForm";
import BookContainer from "./Components/Book/BookContainer";
import Footer from "./Components/Footer";

const App = () => {
	return (
		<div>
			<Header />
			<Container>
				<AddForm />
				<BookContainer />
			</Container>
			<Footer />
		</div>
	);
};

export default App;
