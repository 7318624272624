import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBooks = createAsyncThunk(
	"book/fetchBooks",
	async (_, thunkAPI) => {
		const { rejectWithValue } = thunkAPI;
		try {
			const res = await fetch(
				"https://66d30321184dce1713cf07e8.mockapi.io/api/v1/books"
			);
			const data = await res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const insertBook = createAsyncThunk(
	"book/insertBook",
	async (bookData, thunkAPI) => {
		const { rejectWithValue, getState } = thunkAPI;
		bookData.author = getState().auth.name;

		try {
			const res = await fetch(
				"https://66d30321184dce1713cf07e8.mockapi.io/api/v1/books",
				{
					method: "POST",
					body: JSON.stringify(bookData),
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
				}
			);
			const data = await res.json();
			console.log(data);

			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteBook = createAsyncThunk(
	"book/deleteBook",
	async (data, thunkAPI) => {
		const { rejectWithValue } = thunkAPI;
		try {
			await fetch(
				`https://66d30321184dce1713cf07e8.mockapi.io/api/v1/books/${data.id}`,
				{
					method: "DELETE",
					headers: {
						"Content-type": "application/json; charset=UTF-8",
					},
				}
			);
			//const data = await res.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

const BookSlice = createSlice({
	name: "book",
	initialState: { books: [], loading: false, error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBooks.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(fetchBooks.fulfilled, (state, action) => {
			state.books = action.payload;
			state.loading = false;
		});
		builder.addCase(fetchBooks.rejected, (state, action) => {
			state.error = action.payload;
			state.loading = false;
		});

		// Insert book
		builder.addCase(insertBook.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(insertBook.fulfilled, (state, action) => {
			state.books.push(action.payload);

			state.loading = false;
		});
		builder.addCase(insertBook.rejected, (state, action) => {
			state.error = action.payload;
			state.loading = false;
		});

		// Delete book
		builder.addCase(deleteBook.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(deleteBook.fulfilled, (state, action) => {
			state.books = state.books.filter((el) => el.id !== action.payload.id);
			state.loading = false;
		});
		builder.addCase(deleteBook.rejected, (state, action) => {
			state.error = action.payload;
			state.loading = false;
		});
	},
});

export default BookSlice.reducer;
