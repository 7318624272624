import { useSelector, useDispatch } from "react-redux";
import { authHandler } from "../store/AuthSlice";

const Header = () => {
	const dispatch = useDispatch();

	const { error } = useSelector((state) => state.books);
	const { loggedIn } = useSelector((state) => state.auth);

	return (
		<div className="">
			{error && (
				<div className="alert alert-danger mb-0" role="alert">
					{error}
				</div>
			)}

			<nav className="navbar navbar-dark bg-dark px-5 ">
				<span className="navbar-brand mb-0 h1">My Books</span>

				<button
					className="btn btn-outline-primary"
					type="submit"
					onClick={() => dispatch(authHandler())}
				>
					{loggedIn ? "Log Out" : "Log In"}
				</button>
			</nav>
		</div>
	);
};

export default Header;
