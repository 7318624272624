const Footer = () => {
	return (
		<footer className="bg-dark text-white text-center py-3 mt-auto">
			<div className="container">
				<p className="mb-0"> 2024 @ Mohammad Sobhy</p>
				<p className="mb-0">
					Big Thanks To
					<a href="https://www.youtube.com/@kimzcodes">Kimz Codes</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
